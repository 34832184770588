const ASSET_CLASSES = [
  { name: 'Robo Advisor', slug: 'robo-advisor' },
  { name: 'Cash', slug: 'cash' },
  { name: 'NFTs', slug: 'nfts' },
  { name: 'Collectibles', slug: 'collectibles' },
  { name: 'Farmland', slug: 'farmland' },
  { name: 'Gold', slug: 'gold' },
  { name: 'High Yield Savings', slug: 'high-yield-savings' },
  { name: 'IRA', slug: 'ira' },
  { name: 'Lending', slug: 'lending' },
  { name: 'Real Estate', slug: 'real-estate' },
  { name: 'Startups', slug: 'startups' },
  { name: 'S&P 500', slug: 'spx' },
  { name: 'Wine', slug: 'wine-1000' },
  { name: 'Sports Cards', slug: 'sport-cards' },
  { name: 'Art', slug: 'contemporary' },
  { name: 'Sports Memorabilia', slug: 'sports-memorabilia' },
  { name: 'Bitcoin', slug: 'crypto' },
  { name: 'Stocks', slug: 'bond' },
  { name: 'Ethereum', slug: 'ethereum' },
  { name: 'Cannabis', slug: 'cannabis' },
  { name: 'Goods', slug: 'consumer-goods' }
  // education fashion finance food-beverage goog post-war aapl
]

const COMPARE_ASSETS_DATE_FORMATS = {
  '24H': 'twentyFourHours',
  '7D': 'sevenDays',
  '30D': 'thirtyDays',
  YTD: 'ytd',
  '1Y': 'oneYear'
}

const COMPARE_ASSETS_DATE = {
  '24H': 'twentyFourHours',
  '7D': 'sevenDays',
  '30D': 'thirtyDays',
  YTD: 'ytd'
}

const COMPARE_GAINERS_LOSERS_DATE = {
  '24H': 'twentyFourHours',
  '7D': 'sevenDays',
  '30D': 'thirtyDays'
}

const QUIZ_PARAMS = [
  {
    title: "What's your main investment goal?",
    field: 'risk',
    btns: [
      {
        title: 'More cash each month',
        value: '1'
      },
      {
        title: 'Long term growth',
        value: '2'
      },
      {
        title: 'A balanced portfolio',
        value: '3'
      },
      {
        title: 'All of the above',
        value: '4'
      }
    ]
  },
  {
    title: 'What would you choose?',
    field: 'cash',
    btns: [
      {
        title: '$1,000 in cash',
        value: '1'
      },
      {
        title: '50% chance to win $5k',
        value: '2'
      },
      {
        title: '25% chance to win $10k',
        value: '3'
      },
      {
        title: '5% chance to win $100k',
        value: '3'
      }
    ]
  },
  {
    title: 'When do you want to see returns?',
    field: 'timeframe',
    btns: [
      {
        title: 'Sooner than 1 year',
        value: '12'
      },
      {
        title: '1-5 years',
        value: '60'
      },
      {
        title: '5+ years',
        value: '72'
      }
    ]
  },
  {
    title: 'Are you an accredited investor?',
    field: 'accredited',
    btns: [
      {
        title: 'Yes',
        value: '1'
      },
      {
        title: 'No',
        value: '2'
      },
      {
        title: 'Unsure',
        value: '4'
      }
    ],
    titleRadio: 'Select what applies to you',
    radios: [
      {
        title: 'I have an annual income of at least $200,000, or $3000,00 if combined with a spouses income',
        value: '1'
      },
      {
        title:
          'I have net worth, or joint net worth with my spouse or partner, exceeds $1,000,000, excluding my primary residence.',
        value: '2'
      },
      {
        title: 'None apply to me',
        value: '3'
      }
    ]
  }
]

const RESULT_DEFAULT = {
  risk: ['1', '2', '3'],
  cash: ['1', '2', '3'],
  timeframe: ['12', '60', '72'],
  accredited: ['1', '2', '3']
}
const SCHEMA = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'MoneyMade',
  url: 'https://moneymade.io',
  logo: 'https://moneymade.io/logo.png',
  sameAs: ['https://web.facebook.com/MoneyMade.io/', 'https://www.linkedin.com/company/money-made/'],
  address: {
    '@type': 'PostalAddress',
    streetAddress: '9378 Wilshire Blvd Suite 300',
    addressLocality: 'Beverly Hills',
    addressRegion: 'CA',
    postalCode: '92109',
    addressCountry: 'US'
  },
  contactPoint: {
    '@type': 'ContactPoint',
    email: 'legal@klaymedia.com',
    contactType: 'Customer Support'
  },
  founder: {
    '@type': 'Person',
    name: 'Richard McBeath',
    email: 'nick@klaymedia.com',
    knowsAbout: ['investments', 'personal finance', 'cryptocurrency', 'alternative assets']
  },
  product: [
    {
      '@type': 'Product',
      name: 'Diamond Standard',
      url: 'https://moneymade.io/discover/diamond-standard',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.7',
        reviewCount: '65'
      }
    },
    {
      '@type': 'Product',
      name: 'Fierce',
      url: 'https://moneymade.io/discover/fierce',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.5',
        reviewCount: '34'
      }
    },
    {
      '@type': 'Product',
      name: 'Binance US',
      url: 'https://moneymade.io/discover/binanceus',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.6',
        reviewCount: '120'
      }
    },
    {
      '@type': 'Product',
      name: 'Lofty',
      url: 'https://moneymade.io/discover/lofty',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.4',
        reviewCount: '43'
      }
    },
    {
      '@type': 'Product',
      name: 'Goldin',
      url: 'https://moneymade.io/discover/goldin',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.7',
        reviewCount: '78'
      }
    },
    {
      '@type': 'Product',
      name: 'Edly',
      url: 'https://moneymade.io/discover/edly',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.2',
        reviewCount: '29'
      }
    },
    {
      '@type': 'Product',
      name: 'Roofstock',
      url: 'https://moneymade.io/discover/roofstock',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.5',
        reviewCount: '97'
      }
    },
    {
      '@type': 'Product',
      name: 'AHP Fund',
      url: 'https://moneymade.io/discover/ahp-fund',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.6',
        reviewCount: '50'
      }
    },
    {
      '@type': 'Product',
      name: 'Ellevest',
      url: 'https://moneymade.io/discover/ellevest',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.3',
        reviewCount: '32'
      }
    },
    {
      '@type': 'Product',
      name: 'Crypto.com',
      url: 'https://moneymade.io/discover/cryptocom',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.5',
        reviewCount: '140'
      }
    },
    {
      '@type': 'Product',
      name: 'Hey Benny',
      url: 'https://moneymade.io/discover/heybenny',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.4',
        reviewCount: '30'
      }
    },
    {
      '@type': 'Product',
      name: 'Everbloom',
      url: 'https://moneymade.io/discover/everbloom',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.3',
        reviewCount: '42'
      }
    },
    {
      '@type': 'Product',
      name: 'Nada',
      url: 'https://moneymade.io/discover/nada',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.5',
        reviewCount: '28'
      }
    },
    {
      '@type': 'Product',
      name: 'CIT',
      url: 'https://moneymade.io/discover/cit',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.7',
        reviewCount: '22'
      }
    },
    {
      '@type': 'Product',
      name: 'Percent',
      url: 'https://moneymade.io/discover/percent',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.8',
        reviewCount: '40'
      }
    },
    {
      '@type': 'Product',
      name: 'Stash',
      url: 'https://moneymade.io/discover/stash',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.6',
        reviewCount: '88'
      }
    },
    {
      '@type': 'Product',
      name: 'Bioverge',
      url: 'https://moneymade.io/discover/bioverge',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.3',
        reviewCount: '20'
      }
    },
    {
      '@type': 'Product',
      name: 'Uphold',
      url: 'https://moneymade.io/discover/uphold',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.7',
        reviewCount: '150'
      }
    },
    {
      '@type': 'Product',
      name: 'WineCap',
      url: 'https://moneymade.io/discover/winecap',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.5',
        reviewCount: '45'
      }
    },
    {
      '@type': 'Product',
      name: 'Wealthfront',
      url: 'https://moneymade.io/discover/wealthfront',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.7',
        reviewCount: '300'
      }
    },
    {
      '@type': 'Product',
      name: 'Upside Avenue',
      url: 'https://moneymade.io/discover/upside-avenue',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.5',
        reviewCount: '19'
      }
    },
    {
      '@type': 'Product',
      name: 'ZacksTrade',
      url: 'https://moneymade.io/discover/zackstrade',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.4',
        reviewCount: '60'
      }
    },
    {
      '@type': 'Product',
      name: 'Constitution Lending',
      url: 'https://moneymade.io/discover/constitution-lending',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.6',
        reviewCount: '22'
      }
    },
    {
      '@type': 'Product',
      name: 'OpenSea',
      url: 'https://moneymade.io/discover/opensea',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.8',
        reviewCount: '310'
      }
    },
    {
      '@type': 'Product',
      name: 'Robinhood',
      url: 'https://moneymade.io/discover/robinhood',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.6',
        reviewCount: '1200'
      }
    },
    {
      '@type': 'Product',
      name: 'Harvest Returns',
      url: 'https://moneymade.io/discover/harvest-returns',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.3',
        reviewCount: '40'
      }
    },
    {
      '@type': 'Product',
      name: 'ARK7',
      url: 'https://moneymade.io/discover/ark7',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.5',
        reviewCount: '24'
      }
    },
    {
      '@type': 'Product',
      name: 'Merrill Edge',
      url: 'https://moneymade.io/discover/merrill-edge',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.6',
        reviewCount: '100'
      }
    },
    {
      '@type': 'Product',
      name: 'SigFig',
      url: 'https://moneymade.io/discover/sigfig',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.7',
        reviewCount: '200'
      }
    },
    {
      '@type': 'Product',
      name: 'Schwab Intelligent Portfolios',
      url: 'https://moneymade.io/discover/schwab-intelligent-portfolios',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.8',
        reviewCount: '95'
      }
    },
    {
      '@type': 'Product',
      name: 'TradeZero America',
      url: 'https://moneymade.io/discover/tradezero-america',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.6',
        reviewCount: '110'
      }
    },
    {
      '@type': 'Product',
      name: 'EnergyFunders',
      url: 'https://moneymade.io/discover/energyfunders',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.4',
        reviewCount: '26'
      }
    },
    {
      '@type': 'Product',
      name: 'SuperRare',
      url: 'https://moneymade.io/discover/superrare',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.5',
        reviewCount: '33'
      }
    },
    {
      '@type': 'Product',
      name: 'Sharestates',
      url: 'https://moneymade.io/discover/sharestates',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.6',
        reviewCount: '30'
      }
    },
    {
      '@type': 'Product',
      name: 'Sweater Ventures',
      url: 'https://moneymade.io/discover/sweater-ventures',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.4',
        reviewCount: '15'
      }
    },
    {
      '@type': 'Product',
      name: 'Coinbase',
      url: 'https://moneymade.io/discover/coinbase',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.7',
        reviewCount: '2400'
      }
    },
    {
      '@type': 'Product',
      name: 'FarmTogether',
      url: 'https://moneymade.io/discover/farmtogether',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.6',
        reviewCount: '34'
      }
    },
    {
      '@type': 'Product',
      name: 'EquityMultiple',
      url: 'https://moneymade.io/discover/equitymultiple',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.7',
        reviewCount: '45'
      }
    },
    {
      '@type': 'Product',
      name: 'Tornado',
      url: 'https://moneymade.io/discover/tornado',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.5',
        reviewCount: '20'
      }
    },
    {
      '@type': 'Product',
      name: 'Acorns',
      url: 'https://moneymade.io/discover/acorns',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.8',
        reviewCount: '1200'
      }
    },
    {
      '@type': 'Product',
      name: 'Concreit',
      url: 'https://moneymade.io/discover/concreit',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.5',
        reviewCount: '70'
      }
    },
    {
      '@type': 'Product',
      name: 'Rally Rd',
      url: 'https://moneymade.io/discover/rallyrd',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.7',
        reviewCount: '140'
      }
    },
    {
      '@type': 'Product',
      name: 'Chime',
      url: 'https://moneymade.io/discover/chime',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.5',
        reviewCount: '600'
      }
    },
    {
      '@type': 'Product',
      name: 'Prosper',
      url: 'https://moneymade.io/discover/prosper',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.4',
        reviewCount: '85'
      }
    },
    {
      '@type': 'Product',
      name: 'Origin Investments',
      url: 'https://moneymade.io/discover/origin-investments',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.6',
        reviewCount: '24'
      }
    },
    {
      '@type': 'Product',
      name: 'Republic',
      url: 'https://moneymade.io/discover/republic',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.8',
        reviewCount: '220'
      }
    },
    {
      '@type': 'Product',
      name: 'NewDay',
      url: 'https://moneymade.io/discover/newday',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.5',
        reviewCount: '33'
      }
    },
    {
      '@type': 'Product',
      name: 'Cadre',
      url: 'https://moneymade.io/discover/cadre',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.7',
        reviewCount: '50'
      }
    },
    {
      '@type': 'Product',
      name: 'Instalend',
      url: 'https://moneymade.io/discover/instalend',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.4',
        reviewCount: '24'
      }
    },
    {
      '@type': 'Product',
      name: 'EarlyBird',
      url: 'https://moneymade.io/discover/earlybird',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.7',
        reviewCount: '40'
      }
    },
    {
      '@type': 'Product',
      name: 'Lively',
      url: 'https://moneymade.io/discover/lively',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.6',
        reviewCount: '15'
      }
    },
    {
      '@type': 'Product',
      name: 'Ally',
      url: 'https://moneymade.io/discover/ally',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.8',
        reviewCount: '250'
      }
    },
    {
      '@type': 'Product',
      name: 'Vanguard Digital Advisor',
      url: 'https://moneymade.io/discover/vanguard-digital-advisor',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.7',
        reviewCount: '350'
      }
    },
    {
      '@type': 'Product',
      name: 'Alinea',
      url: 'https://moneymade.io/discover/alinea',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.5',
        reviewCount: '60'
      }
    },
    {
      '@type': 'Product',
      name: 'Forge',
      url: 'https://moneymade.io/discover/forge',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.6',
        reviewCount: '25'
      }
    },
    {
      '@type': 'Product',
      name: 'iFlip',
      url: 'https://moneymade.io/discover/iflip',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.7',
        reviewCount: '30'
      }
    },
    {
      '@type': 'Product',
      name: 'Dizraptor',
      url: 'https://moneymade.io/discover/dizraptor',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.4',
        reviewCount: '20'
      }
    },
    {
      '@type': 'Product',
      name: 'Roofstock One',
      url: 'https://moneymade.io/discover/roofstock-one',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.6',
        reviewCount: '45'
      }
    },
    {
      '@type': 'Product',
      name: 'Firstrade',
      url: 'https://moneymade.io/discover/firstrade',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.5',
        reviewCount: '90'
      }
    },
    {
      '@type': 'Product',
      name: 'Goldfinch',
      url: 'https://moneymade.io/discover/goldfinch',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.7',
        reviewCount: '25'
      }
    },
    {
      '@type': 'Product',
      name: 'Arborcrowd',
      url: 'https://moneymade.io/discover/arborcrowd',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.5',
        reviewCount: '26'
      }
    },
    {
      '@type': 'Product',
      name: 'Collectable',
      url: 'https://moneymade.io/discover/collectable',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.6',
        reviewCount: '22'
      }
    },
    {
      '@type': 'Product',
      name: 'Qapital',
      url: 'https://moneymade.io/discover/qapital',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.7',
        reviewCount: '150'
      }
    },
    {
      '@type': 'Product',
      name: 'Arrived Homes',
      url: 'https://moneymade.io/discover/arrived-homes',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.5',
        reviewCount: '35'
      }
    },
    {
      '@type': 'Product',
      name: 'Wefunder',
      url: 'https://moneymade.io/discover/wefunder',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.6',
        reviewCount: '110'
      }
    },
    {
      '@type': 'Product',
      name: 'Moomoo',
      url: 'https://moneymade.io/discover/moomoo',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.7',
        reviewCount: '75'
      }
    },
    {
      '@type': 'Product',
      name: 'Royal',
      url: 'https://moneymade.io/discover/royal',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.4',
        reviewCount: '30'
      }
    },
    {
      '@type': 'Product',
      name: 'Title3Funds',
      url: 'https://moneymade.io/discover/title3funds',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.7',
        reviewCount: '20'
      }
    },
    {
      '@type': 'Product',
      name: 'Beanstox',
      url: 'https://moneymade.io/discover/beanstox',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.4',
        reviewCount: '40'
      }
    },
    {
      '@type': 'Product',
      name: 'PWCC',
      url: 'https://moneymade.io/discover/pwcc',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.8',
        reviewCount: '95'
      }
    },
    {
      '@type': 'Product',
      name: 'Finch',
      url: 'https://moneymade.io/discover/finch',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.5',
        reviewCount: '60'
      }
    },
    {
      '@type': 'Product',
      name: 'Luno',
      url: 'https://moneymade.io/discover/luno',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.6',
        reviewCount: '90'
      }
    },
    {
      '@type': 'Product',
      name: 'OneGold',
      url: 'https://moneymade.io/discover/onegold',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.5',
        reviewCount: '70'
      }
    },
    {
      '@type': 'Product',
      name: 'Quantbase',
      url: 'https://moneymade.io/discover/quantbase',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.7',
        reviewCount: '35'
      }
    },
    {
      '@type': 'Product',
      name: 'Axos Bank',
      url: 'https://moneymade.io/discover/axos-bank',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.8',
        reviewCount: '200'
      }
    },
    {
      '@type': 'Product',
      name: 'InvestX',
      url: 'https://moneymade.io/discover/investx',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.5',
        reviewCount: '50'
      }
    },
    {
      '@type': 'Product',
      name: 'iintoo',
      url: 'https://moneymade.io/discover/iintoo',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.6',
        reviewCount: '40'
      }
    },
    {
      '@type': 'Product',
      name: 'American Express',
      url: 'https://moneymade.io/discover/american-express',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.7',
        reviewCount: '300'
      }
    },
    {
      '@type': 'Product',
      name: 'Varo',
      url: 'https://moneymade.io/discover/varo',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.6',
        reviewCount: '95'
      }
    },
    {
      '@type': 'Product',
      name: 'Coinbase NFT',
      url: 'https://moneymade.io/discover/coinbase-nft',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.7',
        reviewCount: '300'
      }
    },
    {
      '@type': 'Product',
      name: 'E-Trade Core',
      url: 'https://moneymade.io/discover/e-trade-core',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.6',
        reviewCount: '120'
      }
    },
    {
      '@type': 'Product',
      name: 'PeerStreet',
      url: 'https://moneymade.io/discover/peerstreet',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.5',
        reviewCount: '80'
      }
    },
    {
      '@type': 'Product',
      name: 'Yieldstreet',
      url: 'https://moneymade.io/discover/yieldstreet',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.6',
        reviewCount: '210'
      }
    },
    {
      '@type': 'Product',
      name: 'StreetShares',
      url: 'https://moneymade.io/discover/streetshares',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.4',
        reviewCount: '37'
      }
    },
    {
      '@type': 'Product',
      name: 'CrowdStreet',
      url: 'https://moneymade.io/discover/crowdstreet',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.7',
        reviewCount: '95'
      }
    },
    {
      '@type': 'Product',
      name: 'Groundfloor',
      url: 'https://moneymade.io/discover/groundfloor',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.6',
        reviewCount: '180'
      }
    },
    {
      '@type': 'Product',
      name: 'Peerform',
      url: 'https://moneymade.io/discover/peerform',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.3',
        reviewCount: '60'
      }
    },
    {
      '@type': 'Product',
      name: 'Fundrise',
      url: 'https://moneymade.io/discover/fundrise',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.8',
        reviewCount: '1000'
      }
    },
    {
      '@type': 'Product',
      name: 'LendingClub',
      url: 'https://moneymade.io/discover/lendingclub',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.4',
        reviewCount: '300'
      }
    },
    {
      '@type': 'Product',
      name: 'Crowdcube',
      url: 'https://moneymade.io/discover/crowdcube',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.5',
        reviewCount: '240'
      }
    },
    {
      '@type': 'Product',
      name: 'NextSeed',
      url: 'https://moneymade.io/discover/nextseed',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.4',
        reviewCount: '70'
      }
    },
    {
      '@type': 'Product',
      name: 'Worthy Bonds',
      url: 'https://moneymade.io/discover/worthy-bonds',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.7',
        reviewCount: '90'
      }
    },
    {
      '@type': 'Product',
      name: 'Honeycomb Credit',
      url: 'https://moneymade.io/discover/honeycomb-credit',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.6',
        reviewCount: '120'
      }
    },
    {
      '@type': 'Product',
      name: 'Neighbor',
      url: 'https://moneymade.io/discover/neighbor',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.5',
        reviewCount: '65'
      }
    },
    {
      '@type': 'Product',
      name: 'Modiv',
      url: 'https://moneymade.io/discover/modiv',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.7',
        reviewCount: '75'
      }
    },
    {
      '@type': 'Product',
      name: 'FundThatFlip',
      url: 'https://moneymade.io/discover/fundthatflip',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.6',
        reviewCount: '85'
      }
    },
    {
      '@type': 'Product',
      name: 'Mainvest',
      url: 'https://moneymade.io/discover/mainvest',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.5',
        reviewCount: '100'
      }
    },
    {
      '@type': 'Product',
      name: 'TruCrowd',
      url: 'https://moneymade.io/discover/trucrowd',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.4',
        reviewCount: '55'
      }
    },
    {
      '@type': 'Product',
      name: 'MyConstant',
      url: 'https://moneymade.io/discover/myconstant',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.5',
        reviewCount: '50'
      }
    },
    {
      '@type': 'Product',
      name: 'Republic Note',
      url: 'https://moneymade.io/discover/republic-note',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.6',
        reviewCount: '85'
      }
    },
    {
      '@type': 'Product',
      name: 'Stacked Invest',
      url: 'https://moneymade.io/discover/stacked-invest',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.4',
        reviewCount: '65'
      }
    },
    {
      '@type': 'Product',
      name: 'Masterworks',
      url: 'https://moneymade.io/discover/masterworks',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.7',
        reviewCount: '240'
      }
    },
    {
      '@type': 'Product',
      name: 'Public',
      url: 'https://moneymade.io/discover/public',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.5',
        reviewCount: '320'
      }
    },
    {
      '@type': 'Product',
      name: 'Artivest',
      url: 'https://moneymade.io/discover/artivest',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.4',
        reviewCount: '60'
      }
    },
    {
      '@type': 'Product',
      name: 'Vinovest',
      url: 'https://moneymade.io/discover/vinovest',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.6',
        reviewCount: '140'
      }
    },
    {
      '@type': 'Product',
      name: 'CrowdStreet Fund',
      url: 'https://moneymade.io/discover/crowdstreet-fund',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.7',
        reviewCount: '90'
      }
    }
  ],
  mainEntityOfPage: {
    '@type': 'CollectionPage',
    url: 'https://moneymade.io/collections',
    hasPart: [
      {
        '@type': 'ItemList',
        name: 'Best Crypto Wallets',
        url: 'https://moneymade.io/collections/best-crypto-wallets',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            url: 'https://moneymade.io/discover/coinbase'
          },
          {
            '@type': 'ListItem',
            position: 2,
            url: 'https://moneymade.io/discover/uphold'
          },
          {
            '@type': 'ListItem',
            position: 3,
            url: 'https://moneymade.io/discover/binanceus'
          }
        ]
      },
      {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            name: 'Discover',
            item: 'https://moneymade.io/discover'
          },
          {
            '@type': 'ListItem',
            position: 2,
            name: 'Trending',
            item: 'https://moneymade.io/trending'
          },
          {
            '@type': 'ListItem',
            position: 3,
            name: 'Reads',
            item: 'https://moneymade.io/reads'
          },
          {
            '@type': 'ListItem',
            position: 4,
            name: 'MoneyMatch',
            item: 'https://moneymade.io/moneymatch'
          },
          {
            '@type': 'ListItem',
            position: 5,
            name: 'Submit Product',
            item: 'https://moneymade.io/submit-product'
          },
          {
            '@type': 'ListItem',
            position: 6,
            name: 'Art Investing',
            item: 'https://moneymade.io/art-investing'
          }
        ]
      },

      {
        '@type': 'ItemList',
        name: 'Top Trading Platforms',
        url: 'https://moneymade.io/collections/top-trading-platforms',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            url: 'https://moneymade.io/discover/robinhood'
          },
          {
            '@type': 'ListItem',
            position: 2,
            url: 'https://moneymade.io/discover/etrade-core'
          },
          {
            '@type': 'ListItem',
            position: 3,
            url: 'https://moneymade.io/discover/firstrade'
          }
        ]
      }
    ]
  }
}

export {
  ASSET_CLASSES,
  COMPARE_ASSETS_DATE_FORMATS,
  COMPARE_ASSETS_DATE,
  SCHEMA,
  QUIZ_PARAMS,
  RESULT_DEFAULT,
  COMPARE_GAINERS_LOSERS_DATE
}
