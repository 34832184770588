import Link from 'next/link'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { P1, useWindowSize, Icon } from '@moneymade/moneymade-ui'

import { HEADER_MENU_DESKTOP } from 'static/menusConstants'

import styles from './HeaderMenu.module.scss'

const HeaderMenu = ({ className, mobileMenu, openDiscoverModal, primaryColor, askExpertPopup }) => {
  const { pathname } = useRouter()
  const { screenWidth } = useWindowSize()

  // Is current link listed in the acceptedLinks key for current URL
  const handleIsActive = acceptedLinks =>
    acceptedLinks.some(link => (pathname.includes(link) && link !== '/') || pathname === link)

  // Checker for disable buton for modal discover
  // const openPlatformModal = useMemo(() => pathname === `/${ROUTES.discover}`, [pathname])

  return (
    <nav className={`${className} ${styles.HeaderMenu}`} style={{ color: '#fff' }}>
      {screenWidth > 1284 ? (
        <>
          {/* <button
            type="button"
            className={`${styles.MenuItem} ${primaryColor === 'dark' ? styles.ActiveDark : ''} ${
              handleIsActive([`/${ROUTES.discover}`, `/${ROUTES.investments}`, `/${ROUTES.assetPage}`]) ||
              openDiscoverModal
                ? `${styles.Active} ${primaryColor === 'dark' ? styles.ActiveDark : ''}`
                : ''
            }`}
            onClick={openPlatformModal ? () => {} : () => handleOpenDiscover()}
          >
            <img className={styles.Icon} src="https://assets.moneymade.io/images/app/Discover.png" alt="Discover" />
            <span className={styles.Label}>Discover</span>
          </button> */}

          {HEADER_MENU_DESKTOP.map(({ id, label, externalLink, link, acceptedLinks }) =>
            externalLink ? (
              <a
                href={externalLink}
                className={`${styles.MenuItem} ${primaryColor === 'dark' ? styles.ActiveDark : ''} ${
                  handleIsActive(acceptedLinks) && !openDiscoverModal
                    ? `${styles.Active} ${primaryColor === 'dark' ? styles.ActiveDark : ''}`
                    : ''
                }`}
                target="_blank"
                rel="noreferrer"
              >
                <span className={styles.Label}>{label}</span>
              </a>
            ) : (
              <Link key={id} href={link} prefetch={false}>
                <a
                  className={`${styles.MenuItem} ${primaryColor === 'dark' ? styles.ActiveDark : ''} ${
                    handleIsActive(acceptedLinks) && !openDiscoverModal
                      ? `${styles.Active} ${primaryColor === 'dark' ? styles.ActiveDark : ''}`
                      : ''
                  }`}
                  rel="noreferrer"
                >
                  <span className={styles.Label}>{label}</span>
                </a>
              </Link>
            )
          )}
        </>
      ) : null}
      {!mobileMenu && screenWidth <= 1284 ? (
        <a
          type="button"
          className={`${styles.AskExperts} ${askExpertPopup.status ? styles.Open : ''} expert`}
          href="https://newsletter.moneymade.io/subscribe" target="_blank" rel="noreferrer"
        >
          <Icon icon="icon-money-thoughts" size={22} />
          {screenWidth > 1284 && <P1 weight="semibold">Art investing</P1>}
        </a>
      ) : null}

      {screenWidth > 1284 ? (
        <a href="https://newsletter.moneymade.io/subscribe" target="_blank" rel="noreferrer" className={styles.GoToArt}>
          <P1 weight="semibold">Art investing</P1>
        </a>
      ) : null}
    </nav>
  )
}

HeaderMenu.propTypes = {
  className: PropTypes.string,
  primaryColor: PropTypes.oneOf(['red', 'dark']),
  mobileMenu: PropTypes.bool,
  openDiscoverModal: PropTypes.bool,
  askExpertPopup: PropTypes.shape({
    status: PropTypes.bool,
    params: PropTypes.string
  })
}

HeaderMenu.defaultProps = {
  className: '',
  primaryColor: 'red',
  mobileMenu: false,
  openDiscoverModal: false,
  askExpertPopup: {
    status: false,
    params: ''
  }
}

export default HeaderMenu
